import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { withRouter } from "react-router-dom";
import { withSnackbar } from "../SnackbarContainer"
import FormContainer from "./FormContainer"
import SearchTabs from "./SearchTabs"
import Filters from "./Filters"
import SearchDisplayContainer from "./DisplayContainer"
import ResultsDisplay from "./ResultsDisplay"
import Pagination from "./Pagination"
import EmptyDataDisplay from "./EmptyDataDisplay";
import CircularProgress from '@material-ui/core/CircularProgress';
import Flex from "../Flex";
import Box from "../Box";

class Container extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleProductRequestClick = this.handleProductRequestClick.bind(this)

    this.state = { query: this.props.query, searchResults: this.props.search_results, resultsCount: this.props.results_count, activeIndex: 'All', page: 1, date: this.props.date, anchorEl: null, loading: false }
  }

  componentDidMount() {
    if (this.props.filter) {

      let index = this.props.filter

      this.setState({
        activeIndex: index,
        filter: this.props.filter
      })
    }
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  handleFilterChange(value) {
    $.ajax({
      type: "GET",
      url: this.props.url,
      data: {
        query: this.state.query, date: value, filter: this.state.activeIndex
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          date: value,
          searchResults: data.search_results,
          resultsCount: data.results_count,
          anchorEl: null
        })
      }
    })
  }

  handleProductRequestClick(e) {
    $.ajax({
      type: "POST",
      url: "/user_product_requests",
      data: {
        user_product_request: {
          user_id: this.props.user.id, product_string: this.state.query
        }
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage("Request has been submitted. We will get back to you shortly.")
      }
    })
  }

  handlePageChange(e, page) {
    let filter = this.state.activeIndex

    $.ajax({
      type: 'GET',
      url: this.props.url,
      data: {
        query: this.state.query, page: page, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        let params = {}

        params["query"] = this.state.query
        params["page"] = page
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${this.props.url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          page: page,
          filter: filter
        })
      }
    })
  }

  handleActiveIndexClick(e, newValue) {
    let filter = newValue

    $.ajax({
      type: 'GET',
      url: this.props.url,
      data: {
        query: this.state.query, filter: filter, date: this.state.date
      },
      dataType: 'json',
      success: (data) => {

        let params = {}
        params["query"] = this.state.query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${this.props.url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          activeIndex: newValue,
          filter: filter
        })
      }
    })
  }

  handleSearchClick(query) {
    $.ajax({
      type: 'GET',
      url: this.props.url,
      data: {
        query: query, filter: this.state.activeIndex
      },
      dataType: 'json',
      success: (data) => {

        let filter = this.state.activeIndex

        // this.props.history.push(`${url}?query=${this.state.query}`);
        let params = {}
        params["query"] = query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${this.props.url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          filter: filter,
          query: query
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>

          <Box height="100%">
            <FormContainer
              query={this.state.query}
              sector={this.props.sector}
              suggestionsUrl={this.props.suggestions_url}
              placeholderText={this.props.search_text}
              onSearch={this.handleSearchClick}
              user={this.props.user}
            />
            
            <Box mt={4}>
              <SearchTabs 
                tabs={this.props.tabs}
                activeIndex={this.state.activeIndex}
                handleActiveIndexClick={this.handleActiveIndexClick}
              />
            </Box>

            <Flex mt={1} alignItems='center' width="40%">
              <Box>
                {`${this.state.resultsCount} ${this.state.resultsCount > 1 || this.state.resultsCount == 0 ? 'results' : 'result'}`}
              </Box>

              <Box ml={3}>
                <Filters 
                  dateOptions={this.props.date_options}
                  date={this.state.date}
                  anchorEl={this.state.anchorEl}
                  handleOpen={this.handleOpen}
                  handleClose={this.handleClose}
                  handleFilterChange={this.handleFilterChange}
                />
              </Box>

              <Box ml='auto'>
                {this.state.loading ? 
                  <CircularProgress />
                : ""}
              </Box>
            </Flex>

            <Flex mb={2} width="80%" mx='auto' height="100%" flexDirection='column' alignItems='stretch'>
              <Box flexGrow={1} flexShrink={0}>

              <ResultsDisplay 
                searchResults={this.state.searchResults}
                resultsCount={this.state.resultsCount}
                activeIndex={this.state.activeIndex}
                query={this.state.query}
                  sector={this.props.sector}
                />

                <EmptyDataDisplay 
                  activeIndex={this.state.activeIndex}
                  query={this.state.query}
                  handleProductRequestClick={this.handleProductRequestClick}
                />

              </Box>
            </Flex>

            <Box mt='auto' alignSelf='center'>
              <Pagination 
                page={this.state.page}
                resultsCount={this.state.resultsCount}
                handlePageChange={this.handlePageChange}
              />
            </Box>
          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(Container))
