import React from 'react';
import Box from "../Box"
import ProductCode from "./ProductCode"

export default function ProductCodeContainer(props) {

  return(
    <React.Fragment>
      <Box height="100%">
        {props.productCodes.map((productCode) =>
          <ProductCode 
            key={productCode.code}
            code={productCode.code}
            deviceName={productCode.device_name}
            handleProductCodeClick={props.handleProductCodeClick}
          />
        )}
      </Box>
    </React.Fragment>
  )
}