import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import OutlinedButton from "../buttons/OutlinedButton"

export default class Filters extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    console.log(this.props.sector.id)

    const selectedCollections = this.props.filterOptions.collections.filter(collection => this.props.filterValues.collection_ids.includes(collection.value) ? collection : null)
    const selectedTargetMarkets = this.props.filterOptions.targetMarkets.filter(targetMarket => this.props.filterValues.target_market_ids.includes(targetMarket.value) ? targetMarket : null)
    const selectedCompanies = this.props.filterOptions.companies.filter(company => this.props.filterValues.company_ids.includes(company.value) ? company : null)
    const selectedTargetSites = this.props.filterOptions.targetSites.filter(targetSite => this.props.filterValues.target_site_ids.includes(targetSite.value) ? targetSite : null)
    const selectedTherapyTypes = this.props.filterOptions.therapyTypes.filter(therapyType => this.props.filterValues.therapy_type_ids.includes(therapyType.value) ? therapyType : null)

    const selectedStentConfigurations = this.props.filterOptions.stentConfigurations.filter(stentConfiguration => this.props.filterValues.stent_configuration_ids.includes(stentConfiguration.value) ? stentConfiguration : null)

    const selectedTechnologyGroups = this.props.filterOptions.technologyGroups ? this.props.filterOptions.technologyGroups.filter(technologyGroup => this.props.filterValues.technology_group_ids.includes(technologyGroup.value) ? technologyGroup : null) : []

    const selectedRegulatoryStatuses = this.props.filterOptions.regulatoryStatuses.filter(regulatoryStatus => this.props.filterValues.regulatory_status_ids.includes(regulatoryStatus.value) ? regulatoryStatus : null)

    const selectedMethods = this.props.filterOptions.methods ? this.props.filterOptions.methods.filter(method => this.props.filterValues.method_type_ids.includes(method.value) ? method : null) : null

    const selectedPolymerTypes = this.props.filterOptions.polymerTypes ? this.props.filterOptions.polymerTypes.filter(polymerType => this.props.filterValues.polymer_type_ids.includes(polymerType.value) ? polymerType : null) : null

    let lgSize = 2

    return (
      <Flex alignItems='center'>

        <Box mr={lgSize} width={1/9}>
          <Select
            multiple
            label='Collections'
            name='collection_ids'
            value={this.props.filterValues.collection_ids}
            entities={this.props.filterOptions.collections}
            handleChange={this.props.handleFilterChange}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {`${selectedCollections.length} chosen`}
              </Flex>
            )}
          />
        </Box>

        <Box mr={lgSize} width={1/9}>
          <Select
            multiple
            label='Company'
            name='company_ids'
            value={this.props.filterValues.company_ids}
            entities={this.props.filterOptions.companies}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {`${selectedCompanies.length} chosen`}
              </Flex>
            )}
          />
        </Box>

        {this.props.sector.monitor_product
        ?
          <Fragment>
            {this.props.sector.id == 27 ?
              <Box mr={lgSize} width={1/6}>
                <Select
                  multiple
                  label='Technology'
                  name='technology_group_ids'
                  value={this.props.filterValues.technology_group_ids}
                  entities={this.props.filterOptions.technologyGroups}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedTechnologyGroups.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            : ""}

            <Box mr={lgSize} width={1/6}>
              <Select
                label='Status'
                name='monitor_status'
                value={this.props.filterValues.monitor_status}
                entities={this.props.filterOptions.monitorStatuses}
                handleChange={this.props.handleFilterChange}
                includeBlank={true}
              />
            </Box>

          </Fragment>
        :
          <Fragment>
            <Box mr={lgSize} width={1/9}>
              <Select
                multiple
                label='Status'
                name='regulatory_status_ids'
                value={this.props.filterValues.regulatory_status_ids}
                entities={this.props.filterOptions.regulatoryStatuses}
                handleChange={this.props.handleFilterChange}
                includeBlank={true}
                renderValue={selected => (
                  <Flex flexWrap='wrap'>
                    {`${selectedRegulatoryStatuses.length} chosen`}
                  </Flex>
                )}
              />
            </Box>

            {this.props.sector.market_id != 3 ?
              <Box mr={lgSize} width={1/9}>
                <Select
                  multiple
                  label='Target Sites'
                  name='target_site_ids'
                  value={this.props.filterValues.target_site_ids}
                  entities={this.props.filterOptions.targetSites}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedTargetSites.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            : ''}

            <Box mr={lgSize} width={1/9}>
              <Select
                multiple
                label='Target Market'
                name='target_market_ids'
                value={this.props.filterValues.target_market_ids}
                entities={this.props.filterOptions.targetMarkets}
                handleChange={this.props.handleFilterChange}
                includeBlank={true}
                renderValue={selected => (
                  <Flex flexWrap='wrap'>
                    {`${selectedTargetMarkets.length} chosen`}
                  </Flex>
                )}
              />
            </Box>

            <Box mr={lgSize} width={1/9}>
              {this.props.sector.abbr == 'Aortic' ?
                <Select
                  multiple
                  label='Stent Configuration'
                  name='stent_configuration_ids'
                  value={this.props.filterValues.stent_configuration_ids}
                  entities={this.props.filterOptions.stentConfigurations}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedStentConfigurations.length} chosen`}
                    </Flex>
                  )}
                />
              :
                <Select
                  multiple
                  label='Therapy'
                  name='therapy_type_ids'
                  value={this.props.filterValues.therapy_type_ids}
                  entities={this.props.filterOptions.therapyTypes}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedTherapyTypes.length} chosen`}
                    </Flex>
                  )}
                />
              }
            </Box>

            {this.props.filterOptions.methods ?
              <Box mr={lgSize} width={1/9}>
                <Select
                  multiple
                  label='Methods'
                  name='method_type_ids'
                  value={this.props.filterValues.method_type_ids}
                  entities={this.props.filterOptions.methods}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedMethods.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            : ""}

            {this.props.filterOptions.polymerTypes ?
              <Box mr={lgSize} width={1/9}>
                <Select
                  multiple
                  label='Polymer Types'
                  name='polymer_type_ids'
                  value={this.props.filterValues.polymer_type_ids}
                  entities={this.props.filterOptions.polymerTypes}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedPolymerTypes.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            : ""}

            <Box>
              <OutlinedButton color="default" onClick={this.props.handleMoreFilterClick}>
                More Filters
              </OutlinedButton>
            </Box>
          </Fragment>
        }

      </Flex>
    )
  }
}
