import React, { Fragment } from "react"
import Flex from "../../../Flex"
import Box from "../../../Box"
import List from "../../../lists/List";
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../../buttons"
import TextInput from "../../../TextInput"
import TrialListItem from "./EntityListItem";

export default function EntitiesList(props) {
  const entityTrialIds = props.entityTrials.map((entityTrial) => entityTrial.trial_id)
  const trialIdArray = [...entityTrialIds, ...props.activeTrials]

  let trialsArray = props.searchedTrials.length > 0 ? props.searchedTrials : props.trials
  return (
    <Fragment>

      <Flex mt={1} alignItems='center'>
        <Box width={1}>
          <TextInput
            name='trialsQuery'
            label='Search'
            value={props.query}
            onChange={props.handleSearchChange}
            className='w-100'
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            helperText='Search by name or NCT id'
          />
        </Box>

        <Box ml={3}>
          <RaisedButton onClick={props.handleSearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>

      <List>
        {trialsArray.map((trial) =>
          <TrialListItem
            key={trial.trial_id}
            trial={trial}
            checked={trialIdArray.indexOf(trial.trial_id) == -1 ? false : true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          />
        )}
      </List>

    </Fragment>
  )
}
