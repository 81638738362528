import React from "react"
import Box from "../Box";
import FilteredList from "../search/FilteredList";



export default function SearchFilters(props) {
  return(
    <React.Fragment>
      <Box width={1} height={1} overflow='auto'>
        {props.databases ? <Box width={1}>
          <FilteredList
            key={props.databases.map(database => `${database.name}-${database.count}`).join('')} 
            label='Databases'
            items={props.databases}
            filterValues={props.filterValues}
            filterName='databases'
            defaultOpen={props.defaultOpen === false ? false : props.recordCount > 0}
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> : ""}

        {/* <Box mt={2}>
          <YearRangeSlider 
            key={props.years.map(year => year.name).join('')} 
            years={props.years}
            originalYears={props.originalYears}
            beginYear={props.filterValues.begin_year}
            endYear={props.filterValues.end_year}
            handleFilterChange={props.handleFilterChange}
          />
        </Box> */}

        {props.products ? <Box mt={3} width={1}>
          <FilteredList
            key={props.products.map(product => product.value).join('')} 
            label='Products'
            items={props.products}
            filterValues={props.filterValues}
            filterName='products'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> : ""}

        {props.manufacturers ? <Box mt={3} width={1}>
          <FilteredList
            key={props.manufacturers.map(manufacturer => manufacturer.name).join('')} 
            label='Manufacturers'
            items={props.manufacturers}
            filterValues={props.filterValues}
            filterName='manufacturers'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> : ""}

        {props.eventTypes ? 
          <Box mt={3} width={1}>
            <FilteredList
              key={props.eventTypes.map(eventType => eventType.name).join('')} 
              label='Event Type'
              items={props.eventTypes}
              filterValues={props.filterValues}
              filterName='event_types'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box> 
        :
            ""
        }

        {props.deviceProblems ? 
          <Box mt={3} width={1}>
            <FilteredList
              key={props.deviceProblems.map(deviceProblems => deviceProblems.name).join('')} 
              label='Device Problem'
              items={props.deviceProblems}
              filterValues={props.filterValues}
              filterName='device_problems'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box> 
        :
            ""
        }

        {/* <Box mt={3} width={1}>
          <FilteredList
            key={props.advisoryCommittees.map(advisoryCommittee => advisoryCommittee.name).join('')}
            label='Advisory Committees'
            items={props.advisoryCommittees}
            filterValues={props.filterValues}
            filterName='advisory_committees'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> */}

        {props.productCodes ? <Box mt={3} width={1}>
          <FilteredList
            key={props.productCodes.map(productCode => productCode.name).join('')}
            label='Product Codes'
            items={props.productCodes}
            filterValues={props.filterValues}
            filterName='product_codes'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box> : ""}
      </Box>
    </React.Fragment>
  )
}