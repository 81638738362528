import React, { Component, Fragment } from "react"
import SourceMaterialsPanel from "./SourceMaterialsPanel"
import ControlPanel from "./ControlPanel"
import update from "immutability-helper"
import Associator from "../Associator"
import EntitiesPanel from "./EntitiesPanel"
import { withSnackbar } from "../../SnackbarContainer"
import SectorCheckboxList from "../associator/SectorCheckboxList"


class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSourceMaterialIndexUpdate = this.handleSourceMaterialIndexUpdate.bind(this)
    this.handlePredictedIndexUpdate = this.handlePredictedIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleMentionedClick = this.handleMentionedClick.bind(this)
    this.handleUnassociateClick = this.handleUnassociateClick.bind(this)
    this.handleTrialSearchChange = this.handleTrialSearchChange.bind(this)
    this.handleTrialSearchClick = this.handleTrialSearchClick.bind(this)
    this.handleTrialUnassociateClick = this.handleTrialUnassociateClick.bind(this)
    this.handleCompleteClick = this.handleCompleteClick.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.state = { activeProducts: [], activeTrials: [], trials: this.props.trials, products: this.props.products, loading: false, activeIndex: 0, sourceMaterialIndex: 0, abstractProducts: this.props.abstract_products, abstractTrials: this.props.abstract_trials, trialSearch: '', searchedTrials: [], predictedIndex: 1, associatorStatus: this.props.abstract_associator_status, abstractSectors: this.props.abstract_sectors }
  }

  componentDidMount() {

    let predictedIndex = this.props.predicted_trials.length == 0 ? 0 : 1

    this.setState({
      predictedIndex: predictedIndex
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handlePredictedIndexUpdate(e, newValue) {
    this.setState({
      predictedIndex: newValue
    })
  }

  handleSourceMaterialIndexUpdate(e, newValue) {
    this.setState({
      sourceMaterialIndex: newValue
    })
  }

  handleCompleteClick(e) {
    if (this.state.associatorStatus) {
      $.ajax({
        type: "PUT",
        url: `/admin/abstract_associator_statuses/${this.state.associatorStatus.id}`,
        data: {
          abstract_associator_status: {
            status: true, user_id: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            associatorStatus: data.abstract_associator_status
          })
          this.props.snackbarShowMessage('Abstract has been marked as completed')
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: "/admin/abstract_associator_statuses",
        data: {
          abstract_associator_status: {
            abstract_id: this.props.abstract.id, status: true, user_id: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            associatorStatus: data.abstract_associator_status
          })
          this.props.snackbarShowMessage('Abstract has been marked as completed')
        },
        error: (data) => {
          this.props.snackbarShowMessage('There was an error. Please try again.', "error")
        }
      })
    }
  }

  handleCheckboxSelect(entity, type) {
    const index = this.state[type].indexOf(entity.id)
    const activeEntities = index == -1 ? update(this.state[type], { $push: [type == 'activeTrials' ? entity.trial_id : entity.id] }) : update(this.state[type], { $splice: [[index, 1]] })

    this.setState({
      [type]: activeEntities
    })
  }

  handleTrialSearchChange(e) {
    if (e.target.value == '') {
      this.setState({
        trialSearch: '',
        searchedTrials: []
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handleTrialSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/search`,
      data: {
        q: this.state.trialSearch
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedTrials: data.trials
        })
      }
    })
  }

  handleAssociateClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/abstracts/${this.props.abstract.id}/associate`,
      data: {
        product_ids: this.state.activeProducts, trial_ids: this.state.activeTrials, created_by: this.props.current_user.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstract: data.abstract,
          abstractProducts: data.abstract_products,
          abstractTrials: data.abstract_trials,
          activeProducts: [],
          activeTrials: []
        })
        this.props.snackbarShowMessage("Abstract has been associated")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Something went wrong. Please try again.")
      }
    })
  }

  handleSectorCheckboxSelect(sector) {
    let abstractSector = this.state.abstractSectors.filter(abstractSector => abstractSector.sector_id == sector.id)[0]

    if (abstractSector) {
      $.ajax({
        type: 'PUT',
        url: `/admin/abstract_sectors/${abstractSector.id}`,
        data: {
          abstract_sector: {
            deleted: true, deleted_by: 1
          }
        },
        dataType: 'JSON',
        success: (data) => {
          let index = this.state.abstractSectors.indexOf(sector)

          let abstractSectors = update(this.state.abstractSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            abstractSectors: abstractSectors
          })
          this.props.snackbarShowMessage("The sector has been removed")
        },
        error: (data) => {
          this.props.snackbarShowMessage("The sector could not be removed", "error")
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/abstract_sectors`,
        data: {
          abstract_sector: {
            abstract_id: this.props.abstract.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let abstractSectors = update(this.state.abstractSectors, {
            $push: [data.abstract_sector]
          })

          this.setState({
            abstractSectors: abstractSectors
          })

          this.props.snackbarShowMessage("The sector was added")
        },
        error: (data) => {
          this.props.snackbarShowMessage("The sector could not be added", "error")
        }
      })
    }
  }

  handleUnassociateClick(abstractProduct) {
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_products/${abstractProduct.id}`,
      data: {
        abstract_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {

        let index = this.state.abstractProducts.indexOf(abstractProduct)
        let abstractProducts = update(this.state.abstractProducts, {
          $splice: [[index, 1]]
        })

        this.setState({
          abstractProducts: abstractProducts
        })
        this.props.snackbarShowMessage('The product has been unassociated')
      },
      error: (data) => {
        this.props.snackbarShowMessage('Something went wrong. Please try again.')
      }
    })
  }

  handleTrialUnassociateClick(abstractTrial) {
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_trials/${abstractTrial.abstract_trials_id}`,
      data: {
        abstract_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {

        let index = this.state.abstractTrials.indexOf(abstractTrial)
        let abstractTrials = update(this.state.abstractTrials, {
          $splice: [[index, 1]]
        })

        this.setState({
          abstractTrials: abstractTrials
        })
        this.props.snackbarShowMessage('The trial has been unassociated')
      },
      error: (data) => {
        this.props.snackbarShowMessage('Something went wrong. Please try again.')
      }
    })
  }

  handleMentionedClick(e) {
    console.log("hello")
  }

  render() {
    let productSearchWords = this.props.predicted_products ? this.props.predicted_products.map(predictedProduct => predictedProduct.product ? predictedProduct.product.short_name : null) : null

    let trialSearchWords = this.props.predicted_trials ? this.props.predicted_trials.map(predictedTrial => predictedTrial.acronym ? predictedTrial.acronym : null) : null


    let searchWords = this.state.predictedIndex == 0 ? productSearchWords : trialSearchWords

    return(
      <Fragment>

        <Associator
          firstPanel={
            <SourceMaterialsPanel
              abstract={this.props.abstract}
              files={this.props.files}
              abstractTexts={this.props.abstract_texts}
              activeIndex={this.state.sourceMaterialIndex}
              handleSourceMaterialIndexUpdate={this.handleSourceMaterialIndexUpdate}
              suggestedAssociations={this.props.suggested_associations}
              searchWords={searchWords}
            />
          }
          secondPanel={
            <Fragment>
              <ControlPanel
                handleAssociateClick={this.handleAssociateClick}
                handleCompleteClick={this.handleCompleteClick}
                associatorStatus={this.state.associatorStatus}
              />

              <SectorCheckboxList
                sectors={this.props.sectors}
                entitySectors={this.state.abstractSectors}
                // predictedSectors={this.props.patent_sector_predictions}
                handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              />
            </Fragment>
          }
          thirdPanel={
            <EntitiesPanel
              predictedProducts={this.props.predicted_products}
              predictedTrials={this.props.predicted_trials}
              products={this.props.products}
              trials={this.state.trials}
              searchedTrials={this.state.searchedTrials}
              abstractTrials={this.state.abstractTrials}
              abstractProducts={this.state.abstractProducts}
              activeProducts={this.state.activeProducts}
              activeTrials={this.state.activeTrials}
              activeIndex={this.state.activeIndex}
              trialSearch={this.state.trialSearch}
              predictedIndex={this.state.predictedIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              handleCheckboxSelect={this.handleCheckboxSelect}
              handleUnassociateClick={this.handleUnassociateClick}
              handleTrialUnassociateClick={this.handleTrialUnassociateClick}
              handleTrialSearchClick={this.handleTrialSearchClick}
              handleTrialSearchChange={this.handleTrialSearchChange}
              handlePredictedIndexUpdate={this.handlePredictedIndexUpdate}
            />
          }
          associationLoading={this.state.associationLoading}
        />
      </Fragment>
    )
  }
}


export default withSnackbar(AssociatorContainer)
