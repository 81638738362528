import React, { Fragment, Component } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableBody, TableHeadSort } from "../tables"
import Select from "../Select"
import Grid from '@material-ui/core/Grid';
import StatusFilterChips from "./StatusFilterChips"
import StatusMarker from "./StatusMarker"
import Box from "../Box"
import Flex from "../Flex"
import { withRouter } from "react-router-dom";
import update from "immutability-helper"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import Checkbox from "../Checkbox"
import { Button, RaisedButton } from "../buttons"
import GridOnIcon from '@material-ui/icons/GridOn';
import IconButton from "../IconButton"
import { CSVLink, CSVDownload } from "react-csv";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

class ClinicalMilestones extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.getStatuses = this.getStatuses.bind(this)
    this.handleCsvClick = this.handleCsvClick.bind(this)
    this.state = { products: this.props.products, filterOptions: { targetSites: this.props.target_sites, targetMarkets: this.props.target_markets, therapyTypes: this.props.therapy_types, companies: this.props.companies, methods: this.props.methods, collections: this.props.collections, worldRegions: this.props.world_regions }, filterValues: { target_site_ids: [], target_market_ids: [], therapy_type_ids: [], company_ids: [], collection_ids: [], method_type_ids: [] }, world_region_id: this.props.world_region_id, approved: this.props.approved, order: 'asc', orderBy: 'short_name', worldRegion: this.props.world_region, loading: false }
  }

  componentDidMount() {
    let filterKeys = Object.keys(this.props.filter_values)
    let filterValueHash = {}

    let parsedFilterValues = filterKeys.map((filterKey) =>
      this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => parseInt(value))
    )

    let filterValues = update(this.state.filterValues, {
      $set: filterValueHash
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleCsvClick(event, done) {
    $.get(`/sectors/${this.props.sector.abbr}/log_csv_download`).then(() => {
      done(); // REQUIRED to invoke the logic of component
    });
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'short_name' ? b[orderBy].toLowerCase() : b['stage_number'] == orderBy ? true : false
    let aOrderKey = orderBy == 'short_name' ? a[orderBy].toLowerCase() : a['stage_number'] == orderBy ? true : false
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, this.state.orderBy) : (a, b) => -this.desc(a, b, this.state.orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getStatuses(filterValues)
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (name == 'approved') {
      params['approved'] = value
    } else if (name == 'world_region_id') {
      params['world_region_id'] = value
    } else if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getStatuses(params)
  }

  getStatuses(params) {
    let url = `/sectors/${this.props.sector.abbr}/products/clinical_milestones`

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/clinical_milestones`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { companies: data.companies, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, collections: data.collections, worldRegions: data.world_regions, methods: data.methods }

        let { approved, world_region_id, ...restOfParams } = params

        let filterKeys = Object.keys(restOfParams)
        let queryParams = filterKeys.map((filterKey) => restOfParams[filterKey].length > 0 ? restOfParams[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        approved == 'not_approved' || approved == 'approved' ? queryParams.push(`status=${approved}`) : ''
        world_region_id ? queryParams.push(`world_region_id=${world_region_id}`) : ''

        let flattenedQueryParams = [].concat.apply([], queryParams);
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          products: data.products,
          filterValues: params,
          approved: data.approved,
          loading: false,
          world_region_id: data.world_region_id,
          worldRegion: data.world_region,
          filterOptions
        })
      },
      error: (data) => {
        this.setState({
          loading: false,
          showSnackbar: true,
          snackbarText: 'There was an error fetching the results. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    let csvHeaders = [ { label: 'Product', key: 'product' }, { label: 'Early Development', key: 'early_development' }, { label: 'FIH', key: 'fih' }, { label: "Feasibility", key: "feasibility" }, { label: 'Approval clinicals', key: 'approval_clinicals' }, { label: 'Approved', key: 'approved' } ]

    let csvData = []

    this.stableSort(this.state.products, this.getSorting(this.state.order, this.state.orderBy)).map((product) => {
      csvData.push({ product: product.short_name, early_development: (product.stage_number == 0 ? "√" : ""), fih: (product.stage_number == 1 ? "√" : ""), feasibility: (product.stage_number == 2 ? "√" : ""), approval_clinicals: (product.stage_number == 3 ? "√" : ""), approved: (product.stage_number == 4 ? "√" : "")  })
    })

    // let csvData = this.state.products.map(product => { product: product.short_name })

    console.log(csvData)

    let approvalClinicalProducts = this.state.products.filter(product => product.stage_number == 3)
    let headers = [ { label: 'Product', name: 'short_name', borderRight: true, align: 'left' }, { label: 'Early Development', name: '0', borderRight: true, align: 'center' }, { label: 'FIH', name: '1', borderRight: true, align: 'center' }, { label: 'Feasibility', name: '2', borderRight: true, align: 'center' }, { label: "Approval Clinicals", name: '3', borderRight: true, align: 'center', buttonComponent: (approvalClinicalProducts.length > 0 ? <span><IconButton size="small" component='a' href={`/sectors/${this.props.sector.abbr}/trials/compare_trials?trial_ids=${approvalClinicalProducts.map(product => product.trial_id)}`} tooltip='Compare Trials'><GridOnIcon  style={{height: '22px', width: '22px'}} /></IconButton></span> : "") }, { label: 'Approved', name: '4', borderRight: true, align: 'center' } ]
    return(
      <Fragment>
        <Paper fullHeight>
          <Box mb={2}>

            <Grid container spacing={2} alignItems='stretch'>

              <Grid item xs>
                <Box>
                  <Select
                    label='Region'
                    name='world_region_id'
                    value={this.state.world_region_id}
                    entities={this.state.filterOptions.worldRegions}
                    handleChange={this.handleFilterChange}
                  />
                </Box>
              </Grid>

              <Grid item xs>
                <Select
                  multiple
                  label='Collections'
                  name='collection_ids'
                  value={this.state.filterValues.collection_ids}
                  entities={this.state.filterOptions.collections}
                  handleChange={this.handleFilterChange}
                  renderValue={selected => (`${selected.length} chosen`)}
                />
              </Grid>

              <Grid item xs>
                <Select
                  multiple
                  label='Company'
                  name='company_ids'
                  value={this.state.filterValues.company_ids}
                  entities={this.state.filterOptions.companies}
                  handleChange={this.handleFilterChange}
                  renderValue={selected => (`${selected.length} chosen`)}
                />
              </Grid>

              {this.props.sector.market_id != 3 ?
                <Grid item xs>
                  <Select
                    multiple
                    label='Target Sites'
                    name='target_site_ids'
                    value={this.state.filterValues.target_site_ids}
                    entities={this.state.filterOptions.targetSites}
                    handleChange={this.handleFilterChange}
                    renderValue={selected => (`${selected.length} chosen`)}
                  />
                </Grid>
              :
                ""
              }

              <Grid item xs>
                <Box>
                  <Select
                    multiple
                    label='Target Market'
                    name='target_market_ids'
                    value={this.state.filterValues.target_market_ids}
                    entities={this.state.filterOptions.targetMarkets}
                    handleChange={this.handleFilterChange}
                    renderValue={selected => (`${selected.length} chosen`)}
                  />
                </Box>
              </Grid>

              <Grid item xs>
                <Box>
                  <Select
                    multiple
                    label='Therapy'
                    name='therapy_type_ids'
                    value={this.state.filterValues.therapy_type_ids}
                    entities={this.state.filterOptions.therapyTypes}
                    handleChange={this.handleFilterChange}
                    renderValue={selected => (`${selected.length} chosen`)}
                  />
                </Box>
              </Grid>

              { this.state.filterOptions.methods ?
                <Grid item xs>
                  <Box>
                    <Select
                      multiple
                      label='Methods'
                      name='method_type_ids'
                      value={this.state.filterValues.method_type_ids}
                      entities={this.state.filterOptions.methods}
                      handleChange={this.handleFilterChange}
                      includeBlank={true}
                      renderValue={selected => (`${selected.length} chosen`)}
                    />
                  </Box>
                </Grid>
              : ""}

              <Grid item xs>
                <Box>
                  <Select
                    label='Status'
                    name='approved'
                    value={this.state.approved}
                    entities={[{ name: 'All', value: 'all' }, { name: 'Approved', value: 'approved' }, { name: 'Not approved', value: 'not_approved' }]}
                    handleChange={this.handleFilterChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Flex>
            <Box>
              <Flex minHeight='28px' alignItems='center' flexWrap='wrap'>
                <StatusFilterChips
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  handleDelete={this.handleDelete}
                />
              </Flex>
            </Box>

            <Box mb={1} ml='auto'>
              <Flex alignItems='center'>
                <Box>
                  {`${Object.keys(this.state.products).length} products`}
                </Box>

                <Box ml={2}>
                  <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename={"market-monitors-product-status.csv"}
                    asyncOnClick={true}
                    onClick={this.handleCsvClick}
                  >
                    <IconButton tooltip='Download CSV file' size='small'>
                      <CloudDownloadIcon style={{height: '22px', width: '22px'}} />
                    </IconButton>
                  </CSVLink>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Box mb={2}>
            <Flex alignItems='center' justifyContent='right'>
              <Box pr={1}>
                <CheckCircleIcon fontSize='small' color='primary' />
              </Box>
              <Box mr={3}>
                =
                <span className='pl1'>
                  {this.state.worldRegion.name}
                </span>
              </Box>

              <Box pr={1}>
                <CheckCircleIcon fontSize='small' />
              </Box>
              <Box>
                =
                Region unknown
              </Box>
            </Flex>
          </Box>


          <Box overflow='auto' height="80%">
            <Table size='small' aria-label='products status table' stickyHeader={true} id='products-status-table'>

              <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                borderRight={true}
              />

              <TableBody>
                <Fragment>
                  {this.state.loading ?
                    <TableRow hover={false}>
                      <TableCell noBorder colSpan={6}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  : ""}

                  {this.stableSort(this.state.products, this.getSorting(this.state.order, this.state.orderBy)).map((product) =>
                    <TableRow key={product.id}>
                      <TableCell borderRight>
                        <Fragment>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                            {product.short_name}
                          </Link>
                          <span className='ml2'>
                            <Link href={`/sectors/${this.props.sector.abbr}/companies/${product.company_id}`}>
                              <Typography variant="caption">
                                {`(${product.company_name})`}
                              </Typography>
                            </Link>
                          </span>
                        </Fragment>
                      </TableCell>
                      <TableCell borderRight>
                        <StatusMarker
                          statusRecord={product}
                          stageNumber={0}
                        />
                      </TableCell>
                      <TableCell borderRight>
                        <StatusMarker
                          statusRecord={product}
                          stageNumber={1}
                        />
                      </TableCell>
                      <TableCell borderRight>
                        <StatusMarker
                          statusRecord={product}
                          stageNumber={2}
                        />
                      </TableCell>
                      <TableCell borderRight>
                        <Fragment>
                          <StatusMarker
                            statusRecord={product}
                            stageNumber={3}
                          />
                        </Fragment>
                      </TableCell>
                      <TableCell borderRight>
                        <StatusMarker
                          statusRecord={product}
                          stageNumber={4}
                        />
                      </TableCell>
                      {/* <TableCell borderRight>
                        <StatusMarker
                          statusRecord={product}
                          stageNumber={6}
                        />
                      </TableCell> */}
                    </TableRow>
                  )}
                </Fragment>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(ClinicalMilestones)
