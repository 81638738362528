import React, { Fragment } from "react"
import List from "../../../lists/List"
import TextInput from "../../../TextInput"
import Flex from "../../../Flex"
import Box from "../../../Box"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../../buttons"
import EntityListItem from "./EntityListItem"

export default function EntitiesList(props) {

  const entityCompanyIds = props.entityCompanies.map((entityCompany) => entityCompany.company_id)
  const companyIdArray = [...entityCompanyIds, ...props.activeCompanies]

  let companyArray = props.searchedCompanies.length > 0 ? props.searchedCompanies : props.companies

  console.log(props.searchedCompanies)
  return (
    <Fragment>

      <Flex alignItems='center'>
        <Box width={1}>
          <TextInput
            name='companyQuery'
            label='Search Companies'
            value={props.query}
            onChange={props.handleSearchChange}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            helperText='Search by name'
          />
        </Box>

        <Box ml='auto'>
          <RaisedButton onClick={props.handleSearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>

      <List>
        {companyArray.map((company) =>
          <EntityListItem
            key={company.id}
            company={company}
            checked={companyIdArray.indexOf(company.id) == -1 ? false : true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          />
        )}
      </List>

    </Fragment>
  )
}
