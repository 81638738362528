import React, { Fragment } from "react"
import IndexContainer from "./IndexContainer"
import AdverseEventsContainer from "./AdverseEventsContainer";
import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function IndexRouter(props) {

  return(
    <Fragment>
      <Switch>
        <Route
          path={`/sectors/${props.sector.abbr}/regulatory/adverse_events`}
          children={<AdverseEventsContainer {...props} />}
        />
        <Route
          path={`/sectors/${props.sector.abbr}/regulatory`}
          children={<IndexContainer {...props} />}
        />
      </Switch>
    </Fragment>
  )
}
